<div id="press-releases" class="container">
  <div class="col-sm-12 col-md-12 col-lg-12">

    <h1>Press</h1>
    <h3>Reviews/Press Releases</h3>
    <p>
      <a href="http://artisthome.org/seattle-rock-vets-unleash-love-and-fury/?fbclid=IwAR30lDrNayx88jP_rnsD5mb_QMW1Mr61S5PBke7u5F7X28Jt3Glro940P_s"
      target="_blank">Artist Home, Tony Kay, 02-22-2019</a>
    </p>

    <p>
    <a href="http://www.northwestmusicscene.net/interview-gretta-harley-discusses-new-project-love-and-fury"
       target="_blank">Northwest Music Scene, 02-15-2019</a>
    </p>
    <div class="container wow fadeIn">
      <div class="col-sm-12 col-md-12 col-lg-12 wow slideInUp" data-wow-duration="1.0s" data-wow-delay="1.750s">
        <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
             src="https://loveandfurymusic.com/uploads/images/band1.jpg"
             alt="LIVE - Love and Fury"/>
        <br/>
      </div>
    </div>
  </div>
</div>
