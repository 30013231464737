
<nav class="navbar navbar-expand-lg navbar-light bg-dark">
<!--  <a class="navbar-brand" href="#">Navbar</a>-->
  <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>-->
<!--  <div class="collapse navbar-collapse show" id="navbarNav">-->
    <ul class="navbar navbar-expand-lg navbar-dark">
      <!--      <li class="nav-item">
       <a routerLink="/home" routerLinkActive="active">Home</a>
           </li>-->




        <ul class="navbar-nav">
         <li><a  class="nav-item nav-link"  [routerLink]="['/home']" [routerLinkActive]="['active']" >Home</a></li>
          <li><a class="nav-item nav-link"  [routerLink]="['/about']" [routerLinkActive]="['active']">The Band</a></li>
          <li><a class="nav-item nav-link"  [routerLink]="['/history']" [routerLinkActive]="['active']">History</a></li>
          <li><a class="nav-item nav-link"  [routerLink]="['/press']" [routerLinkActive]="['active']">Press/Reviews</a></li>
          <li><a class="nav-item nav-link"  [routerLink]="['/specs']" [routerLinkActive]="['active']">Stage Specs</a></li>
<!--          <li><a class="nav-item nav-link"  [routerLink]="['/demos']" [routerLinkActive]="['active']">Demos</a></li>-->
        </ul>


<!--      <li class="nav-item">
        <a routerLink="/history" routerLinkActive="active">History</a>
      </li>-->
    </ul>

<!--  </div>-->
</nav>
