
<div class="wow fadeIn">
  <div class="col-sm-12 col-md-12 col-lg-12 wow fadeIn" data-wow-duration="1.0s" data-wow-delay="1.750s">
    <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
         src="https://loveandfurymusic.com/uploads/images/page-top.jpg"
         alt="LIVE - Love and Fury"/>
    <br/>
    <span class="small-text">Photo: Andrew Kvenvolden</span>
  </div>
  <br />
  <br />
</div>



<div class="container" style="margin-bottom:20px;">
  <h1>Band</h1>
   <h3>Gretta Harley</h3>

  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/gretta-debut-show-clockout.jpg" alt="Gretta Harley"/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Gretta Harley</h3>
    <p>Gretta Harley- Vocals. Gretta is a composer, multi-instrumentalist, performer, and writer. She has been in several bands over the years as a vocalist, guitarist and keyboard player, including Mettle, We Are Golden, Monstro, Eyefulls, Danger Gens and Maxi Badd. She released a solo record, Element 115, Uup (produced by Kramer) on her own label, Mettle Records in 2015. She produced the compilation, “Home Alive: The Art of Self-Defense” on Epic/Sony Records; and co-wrote and co-produced the rock music play, “These Streets” about the untold women musicians in Seattle during the grunge years. She was a faculty member, composer-in-residence and music director at Cornish College of the Arts for eleven years. When she’s not playing music she is teaching it in her home studio, and working on a book. For more information: http://www.grettaharleymusic.com Photo by: Andrew Kvenvolden</p>
  </div>
</div>
<div class="container" style="margin-bottom:20px;">
  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/band-gary-good-cropped.jpg" alt=""/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Gary King</h3>
    <p>Gary King- Guitars. Multi-instrumentalist (guitar, bass, keyboards), record producer, recording engineer, musician and composer. Gary has produced bands of many genres. He has created music for radio and television as well as his own projects, most notably, Love and Fury, Slippage (https://www.reverbnation.com/slippagemusic), and Moon Lit Mad. As the owner/chief engineer at House of Leisure, located in Seattle between 1991 and 1996, Gary enjoyed working with first time recording artists to world-class musicians. His short list of clients include, Hater (SubPop), Hater the 2nd (Barsuk Records), Soundgarden (A&M Records), Gruntruck (Roadrunner Records), Suction, Sony Records, CZ Records, Loosegroove and many others. Photo by: Andrew Kvenvolden</p>
  </div>
</div>

<div class="container" style="margin-bottom:20px;">
  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/jane_mabry_792_bokeh_highres.jpg" alt="Jane Mabry Smith"/>
    <img src="https://loveandfurymusic.com/uploads/images/jane.jpg" alt="Jane Mabry Smith"/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Jane Mabry Smith</h3>
    <p>Bass and backup vocals. Jane is Seattle based composer, multi-instrumentalist vocalist, and lyricist combining existentialist poetry with music to create deep soundscapes. Her numerous collaborators involve Julie Slick (Adrian Belew Power Trio), Alice Bag (The Bags), and Petra Haden (Haden Triplets) and she is a member of Robert Fripp’s Orchestra of Crafty Guitarists XIII. She's played festivals such as SxSW, Women Who Rock and various clubs around the world.</p>
  </div>
</div>

<div class="container" style="margin-bottom:20px;">
  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/bob-slide.jpg" alt=""/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Bob Watanabee</h3>
    <p>Guitars. When Bob is not running around the country for a software company he is playing guitar with Love and Fury and raising future musicians. He is a seasoned studio guitarist and has played with several people including Ric Vaughan, Perry Morgan, Jason Webley, Lenny Burnett and others. He spent his youth in college getting degrees in music and ethnomusicology. Photo by: Andrew Kvenvolden</p>
  </div>
</div>

<div class="container" style="margin-bottom:20px;">
  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/kai.jpg" alt=""/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Kai Strandskov</h3>
    <p>Kai started his formative years playing trumpet in a vibrant music scene in Ashland, OR. He soon expanded to percussion in local experimental rock band, Smidgen. After traveling around for a bit he eventually landed in Seattle, expanding his range to prog rock, indie rock, Balkan and Romani, klezmer, doom, electronic, marching, and random avant garde. Recently, Kai toured with Bucharest Drinking Team, The m9, Dama Libra, and The Debaucherauntes. Lately, he's been writing music for Theremin and playing in Stereo Creeps. He also has a solo project called ROBOrT. Photo by: Andrew Kvenvolden
    </p>
  </div>
</div>

<div class="container" style="margin-bottom:20px;">
  <div class="col-sm-12 col-md-6 col-lg-6">
    <img src="https://loveandfurymusic.com/uploads/images/rob-knop-at-piano.jpg" alt=""/>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6">
    <h3>Rob Knop</h3>
    <div>Kaleidoscopic keyboardist, soft noise specialist, info jazz journalist, ambient thrash music pioneer. Purveyor of the Hydrogen Jukebox and other modes of auditory transport. Maligned inventor of the Portable Paranoia Piano Kit, (although no one speaks of this life chapter anymore). Compulsive innocent, romantic rantic, on the tenure track, responding well. Leading the battle against exploding head syndrome. Composer of tones, songs, tomes, peer of contortionist styling, a writer of words that catch yourself smiling.</div>
  </div>
</div>

<!--
  <div>
     <img class="margin-10" src="https://loveandfurymusic.com/uploads/images/load-in-03-25-2018.jpg" alt="Load in">
 </div>

 <div>
     <img class="margin-10" src="https://loveandfurymusic.com/uploads/images/sovtek-zinky.jpg" alt="Sovtek Zinky">
 </div>-->

<br/><br/>
