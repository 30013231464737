<!--<script src="../../assets/js/wow.min.js"></script>-->
<div class="img-page-top-center">
  <a [routerLink]="['/listen']" [routerLinkActive]="['active']">
    <img class="hd-hero" src="https://loveandfurymusic.com/uploads/images/band-slims-08-19-2019aaa.jpg"
         alt="Love and Fury - Listen to Planet"/></a>
</div>

<div id="{{pageId}}" class="container">
  <div class="content">

<!--    <h1>{{pageName}}</h1>-->

    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-6">

        <p>Love and Fury is a Seattle-based original rock band. Its wall of distorted, well-crafted guitars with psychedelic overtones are led by Gary King and Bob Watanabe. A tight, hard hitting and undulating rhythm section is held down by Jane Mabry Smith (bass) and Kai Strandskov (drums). Rob Knop (keys) flourishes the integrate arrangements; while Gretta Harley’s strong, haunting and melodious vocals paint lyrics about the days we live in. Love and Fury is just that. It rocks you in the face while offering you hope and beauty. We are nothing without you, listener and show goer. We can’t wait for you to hear the music.</p>

        <h3>Reviews</h3>

        <p>"Harley’s words deliver a potent condemnation of sexism and hypocrisy in poetic yet
          forceful fashion. “Unearth the lies, then face them down/expose you to the
          sirens/we’ll crowd you out, we’ll drown you out,” she sings. You won’t find a better
          example of the polar extremes of the band’s name being wrought in vivid, thrilling
          fashion."</p>

        <p>"King’s riffs oscillate pretty consistently between end-of-days power (the opener,
          “Scorpio”) and warbling psychedelic texture (“Kali”), but he’s a nimble songwriter
          whose compositions cast a wide net stylistically. “Ling” is a drama-infused, gothic
          piano ballad, while “Alchemy” throws down a forceful, slingshot bass and some
          near-U2 anthemic soaring. Like all the best rock records, Planet manages to be a
          consistent sum of its parts without sounding like ten iterations of the same
          song..."

          <br/>

          <a
            href="http://artisthome.org/seattle-rock-vets-unleash-love-and-fury/?fbclid=IwAR0wTb1gXRAloAITZ5WPzWX-0AhISWK2zwWJS3WW-nEer80fT9bnBpenz9Y"
            target="_blank">~ Tony Kay, Artist Home</a>
        </p>

      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">

        <div class="padding-20">
          <ul class="list-unstyled">
            <li>
              <img src="https://loveandfurymusic.com/uploads/images/gretta-stage2.jpg"
                   alt="Love and Fury - Listen to Planet"/>
            </li>
          </ul>
        </div>
      </div>


      <div class="container-fluid wow fadeIn">

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/gretta-jane.jpg"
               alt="Record Release Poster"/>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingTwo">{{headingTwo}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/gary1.jpg"
               alt="Gary - Love and Fury"/>
          <p></p>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInRight" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/bob1.jpg"
               alt="Bob - Love and Fury"/>
          <br/>
        </div>
      </div>


      <div class="container-fluid wow fadeIn">
        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/jane1.jpg"
               alt="LIVE - Love and Fury"/>
          <br/>
        </div>


        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInUp" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingTwo">{{headingTwo}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/kai2.jpg"
               alt="Kai - Love and Fury"/>
          <br/>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInRight" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/rob-knop-at-piano.jpg"
               alt="Rob - Love and Fury"/>

        </div>
        <br/>

      </div>


      <!-- MailChimp -->
      <div class="container" style="max-width: 500px;">

        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
              rel="stylesheet"
              type="text/css">
        <style type="text/css">
          #mc_embed_signup {
            background: #d76000;
            color: #ffffff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 100%;
          }

          /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
             We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
        </style>
        <div id="mc_embed_signup">
          <form
            action="https://loveandfurymusic.us19.list-manage.com/subscribe/post?u=acfff7754254a0672714df83c&amp;id=d4d894c15a"
            method="post" id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <label for="mce-EMAIL" style="color: #fff;">Add me to your mailing
                list</label>
              <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL"
                     placeholder="email address"
                     required>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text"
                       name="b_acfff7754254a0672714df83c_d4d894c15a"
                       tabindex="-1"
                       value="">
              </div>
              <div class="clear"><input type="submit" value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe" class="button">
              </div>
            </div>
          </form>
        </div>
      </div><!-- end MailChimp -->


      <div class="container row bg-orange wow fadeIn" data-wow-delay=".5s">

        <div class="col">
          <div class="small-text">Photos by: Andrew Kvenvolden</div>

          <div class="small-text">Website: Gary King</div>

          <br/><br/><br/>
        </div><!-- end col -->

        <div class="col-12">
          <br/>
          <!--<img src="https://loveandfurymusic.com/uploads/images/tshirt-mens.jpg"
               alt="Love and Fury" style="max-width: 400px;"/>-->

        </div>


      </div><!--end bg-orange -->


    </div>

  </div>

</div>
