<footer>

      <ul class="list-unstyled "><!-- list-inline -->
        <li><a class="nav-item nav-link"  [routerLink]="['/home']" [routerLinkActive]="['active']" >Home</a></li>
        <li><a class="nav-item nav-link"  [routerLink]="['/about']" [routerLinkActive]="['active']">The Band</a></li>
        <li><a class="nav-item nav-link"  [routerLink]="['/history']" [routerLinkActive]="['active']">History</a></li>
        <li><a class="nav-item nav-link"  [routerLink]="['/press']" [routerLinkActive]="['active']">Press/Reviews</a></li>
        <li><a class="nav-item nav-link"  [routerLink]="['/specs']" [routerLinkActive]="['active']">Stage Specs</a></li>
       <!-- <li><a (click)="authService.login()" *ngIf="!authService.isLoggedIn">Log In</a></li>&ndash;&gt;
        <li><a (click)="authService.logout()" *ngIf="authService.isLoggedIn">Log Out</a></li>-->
      </ul>

</footer>
